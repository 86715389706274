<template>
  <div>
    <div class="px-5 pt-3 pb-5">
      <div class="mt-5">
        <div class="row">
          <div
            v-for="job in savedJobs"
            :key="job.id"
            @click="goToJob(job._id)"
            class="col-lg-4"
          >
            <job-card :job="job" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JobCard from "@/components/JobCard"
export default {
  name: "SavedJobs",
  components: {
    JobCard,
  },
  data() {
    return {
      savedJobs: [],
    }
  },
  methods: {
    async getJobById() {
      const profileId = this.$store.state.userProfile?._id
      /*
      this.$store.dispatch("api/updateProfile", {
        profileId,
        data: { lastActivity: new Date() },
      })
      */
      if (profileId) {
        try {
          for (let i of this.$store.state.userProfile.savedJobs) {
            const data = await this.$store.dispatch(
              "jobs/dispatchFetchJobById",
              { id: i }
            )
            if (data) {
              this.savedJobs.push(data)
            }
          }
        } catch (error) {
          this.logDevError(error)
        }
      }
    },
    goToJob(id) {
      this.$router.push("/jobs/" + id)
    },
  },
  mounted() {
    this.getJobById()
  },
}
</script>

<style scoped lang="scss"></style>
