import { userDefaults } from "./state"

const mutations = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width
  },

  // VxAutoSuggest

  UPDATE_STARRED_PAGE(state, payload) {
    // find item index in search list state
    const index = state.navbarSearchAndPinList["pages"].data.findIndex(
      (item) => item.url == payload.url
    )

    // update the main list
    state.navbarSearchAndPinList["pages"].data[index].is_bookmarked =
      payload.val

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList["pages"].data[index])
    } else {
      // find item index from starred pages
      const index = state.starredPages.findIndex(
        (item) => item.url == payload.url
      )

      // remove item using index
      state.starredPages.splice(index, 1)
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10)
    state.starredPages = list.concat(starredPagesMore)
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false
    const lastItemInStarredLimited = state.starredPages[10]
    const starredPagesLimited = state.starredPages.slice(0, 10)
    state.starredPages = starredPagesLimited.concat(list)

    state.starredPages.slice(0, 10).map((i) => {
      if (list.indexOf(i) > -1) downToUp = true
    })

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited)
    }
  },

  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY(state, val) {
    state.bodyOverlay = val
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val
  },
  UPDATE_THEME(state, val) {
    state.theme = val
  },
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width
  },
  UPDATE_WINDOW_SCROLL_Y(state, val) {
    state.scrollY = val
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  SET_TOKEN(state, payload) {
    const { accessToken } = payload
    state.accessToken = accessToken
  },
  // Updates user info in state and localstorage
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    const userInfo = JSON.parse(localStorage.getItem("userInfo")) || state.user
    const denullifiedPayload = Object.entries(payload).reduce(
      (denullified, [key, value]) => {
        return value !== null
          ? {
              ...denullified,
              [key]: value,
            }
          : denullified
      },
      {}
    )
    const updatedUser = {
      ...userInfo,
      ...denullifiedPayload,
    }
    state.user = updatedUser
    localStorage.setItem("userInfo", JSON.stringify(updatedUser))
  },
  CLEAR_USER_INFO(state) {
    localStorage.removeItem("userInfo")
    state.user = userDefaults
  },
  UPDATE_USER_PROFILE(state, userProfile) {
    let user = state.user
    state.userProfile = userProfile
    if (typeof state.userProfile.profilePhoto !== "string") {
      state.userProfile.profilePhoto = userProfile.profilePhoto
    }
    localStorage.setItem("userProfile", JSON.stringify(userProfile))
    const profileType =
      user?.role == "support"
        ? "admin"
        : userProfile
        ? userProfile.jobSeeker
          ? "candidate"
          : userProfile.provider
          ? "provider"
          : userProfile.vendor
          ? "vendor"
          : userProfile.educational
          ? "educational"
          : null
        : null
    if (userProfile || user.role == "support") {
      state.profileType = profileType
      localStorage.setItem("profileType", profileType)
    }
  },
  /*
  ADD_PROFILE_PICTURE(state, profilePicture) {
    //const imageUrl = URL.createObjectURL(profilePicture)
    //state.userProfile.profilePhoto = imageUrl
  },
  ADD_SHOWCASE_PICTURES(state, showcasePictures) {
    console.log("showcase", showcasePictures)
    let arr = []
    for (const p of showcasePictures) {
      const imageUrl = URL.createObjectURL(p)
      arr.push(imageUrl)
    }
    console.log("Arr", arr)
    //state.userProfile.showcasePhotos = arr
  },
  */
  CLEAR_USER_PROFILE(state) {
    localStorage.removeItem("userProfile")
    state.userProfile = null
  },
}

export default mutations
