// import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList'
// import themeConfig from '@/../themeConfig.js'
// import colors from '@/../themeConfig.js'

// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////

// *From Auth - Data will be received from auth provider
export const userDefaults = {
  uid: 0, // From Auth
  displayName: "", //"John Doe", // From Auth
  about: "", //"Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
  photoURL: require("@/assets/images/portrait/small/sample-avatar.jpg"), // From Auth
  profilePhoto: require("@/assets/images/portrait/small/sample-avatar.jpg"), // From Auth
  profilePhotoUrl: "",
  status: "online",
  userRole: "user",
}

const ls = typeof localStorage !== "undefined" ? localStorage : null

const storedUser = ls ? ls.getItem("userInfo") : null
const storedProfile = ls ? ls.getItem("userProfile") : null

const user = storedUser ? JSON.parse(storedUser) : userDefaults
const userProfile = storedProfile ? JSON.parse(storedProfile) : null
const profileType =
  user?.role == "support"
    ? "admin"
    : userProfile
    ? userProfile.jobSeeker
      ? "candidate"
      : userProfile.provider
      ? "provider"
      : userProfile.vendor
      ? "vendor"
      : userProfile.educational
      ? "educational"
      : null
    : null

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  const prefixes = " -webkit- -moz- -o- -ms- ".split(" ")
  const mq = function (query) {
    return window.matchMedia(query).matches
  }

  if ("ontouchstart" in window || window.DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("")
  return mq(query)
}

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  user,
  userProfile,
  profileType,
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  is_touch_device: is_touch_device(),
  // mainLayoutType: themeConfig.mainLayoutType || "vertical",
  // navbarSearchAndPinList,
  // reduceButton: themeConfig.sidebarCollapsed,
  verticalNavMenuWidth: "default",
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  // starredPages: navbarSearchAndPinList["pages"].data.filter(
  //     page => page.is_bookmarked
  // ),
  // theme: themeConfig.theme || "light",
  // themePrimaryColor: colors.primary,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,
  accessToken: null,
}

export default state
