import InnerLayout from "@/layout/InnerLayout"
import store from "@/store/store.js"

const navItems = [
  {
    title: "Search Jobs",
    to: "/jobs/search",
  },
  {
    title: "View Job Briefings",
    to: "/jobs/briefings",
  },
]

export const jobsRouter = [
  {
    path: "/jobs",
    redirect: "/jobs/search",
    component: InnerLayout,
    props: { navItems },
    children: [
      {
        path: "search",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Jobs/SearchJobs.vue"
          ),
      },
      {
        path: "briefings",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Jobs/JobBriefings.vue"
          ),
      },
      {
        name: "jobDetail",
        path: ":jobId",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Jobs/JobDetail.vue"
          ),
        beforeEnter: (to, from, next) => {
          const userType = store.state.userProfile.type

          if (userType === "jobSeeker") {
            let payload = {
              jobId: to.params.jobId,
              jobseekerId: store.state.userProfile._id,
              userType: userType,
              activityType: "view-job-details",
            }
            store.dispatch("api/createLog", payload)
          }

          next()
        },
      },
      {
        path: ":jobId/matches",
        name: "jobMatches",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Jobs/JobMatches.vue"
          ),
      },
      {
        path: ":jobId/applicants",
        name: "jobApplicants",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Jobs/JobMatches.vue"
          ),
      },
      {
        path: ":jobId/matches/:profileId",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Jobs/JobMatchProfileDetail.vue"
          ),
      },
      {
        path: ":jobId/success",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Jobs/JobResponse.vue"
          ),
      },
      {
        path: ":jobId/response",
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ "@/views/Jobs/SearchJobs.vue"
          ),
      },
    ],
  },
]
