var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"my-4"},[_vm._m(0),_c('base-input',{class:{
      'input-group-alternative': true,
      'input-error': _vm.errors.includes('name'),
    },attrs:{"placeholder":"Project name","type":"text"},model:{value:(_vm.project.name),callback:function ($$v) {_vm.$set(_vm.project, "name", $$v)},expression:"project.name"}}),(_vm.errors.includes('name'))?_c('span',{staticClass:"font-14-500 invalid"},[_vm._v("Required")]):_vm._e(),_vm._m(1),_c('base-input',{class:{
      'input-group-alternative': true,
      'input-error': _vm.errors.includes('name'),
    },attrs:{"placeholder":"Type of Project","type":"text"},model:{value:(_vm.project.type),callback:function ($$v) {_vm.$set(_vm.project, "type", $$v)},expression:"project.type"}}),(_vm.errors.includes('type'))?_c('span',{staticClass:"font-14-500 invalid"},[_vm._v("Required")]):_vm._e(),_vm._m(2),_c('b-form-textarea',{class:{ 'input-error': _vm.errors.includes('description') },attrs:{"placeholder":"Brief description of the project","rows":"6"},model:{value:(_vm.project.description),callback:function ($$v) {_vm.$set(_vm.project, "description", $$v)},expression:"project.description"}}),(_vm.errors.includes('description'))?_c('span',{staticClass:"font-14-500 invalid"},[_vm._v("Required")]):_vm._e(),_vm._m(3),_c('base-input',{class:{
      'input-group-alternative': true,
      'input-error': _vm.errors.includes('zip'),
    },attrs:{"placeholder":"ZIP Code","type":"text"},on:{"keyup":function($event){return _vm.validateZip(_vm.project.zip)}},model:{value:(_vm.project.zip),callback:function ($$v) {_vm.$set(_vm.project, "zip", $$v)},expression:"project.zip"}}),(_vm.invalidZip || _vm.errors.includes('zip'))?_c('span',{staticClass:"font-14-500 invalid"},[_vm._v(" Please enter a valid ZIP code ")]):_vm._e(),_vm._m(4),_c('base-input',{class:{
      'input-group-alternative': true,
      'input-error': _vm.errors.includes('startDate'),
    },attrs:{"placeholder":"Select date","type":"date"},model:{value:(_vm.project.startDate),callback:function ($$v) {_vm.$set(_vm.project, "startDate", $$v)},expression:"project.startDate"}}),(_vm.errors.includes('startDate'))?_c('span',{staticClass:"font-14-500 invalid"},[_vm._v("Required")]):_vm._e(),_vm._m(5),_c('div',{class:{ 'input-error': _vm.errors.includes('contractorTradeCategories') }},[_c('CustomMultiSelect',{attrs:{"selectOptions":_vm.categoryOptions,"selectedList":_vm.project.contractorTradeCategories,"selectLabel":"Select Trade Categories"},on:{"remove-option":_vm.removeCategory,"select-option":_vm.addCategory}}),(_vm.errors.includes('contractorTradeCategories'))?_c('span',{staticClass:"font-14-500 invalid"},[_vm._v("Required")]):_vm._e()],1),_vm._m(6),_c('b-form-group',{staticClass:"gfb-radio-container",attrs:{"required":""}},[_c('b-form-radio-group',{attrs:{"options":_vm.trueFalse},model:{value:(_vm.project.inviteVendorsToView),callback:function ($$v) {_vm.$set(_vm.project, "inviteVendorsToView", $$v)},expression:"project.inviteVendorsToView"}}),(_vm.errors.includes('inviteVendorsToView'))?_c('span',{staticClass:"font-14-500 invalid"},[_vm._v("Required")]):_vm._e()],1),(_vm.project.inviteVendorsToView === 'true')?_c('multiselect',{attrs:{"placeholder":"Search or add a category","options":_vm.categoryOptions,"multiple":true,"taggable":true,"close-on-select":false},model:{value:(_vm.project.vendorTradeCategories),callback:function ($$v) {_vm.$set(_vm.project, "vendorTradeCategories", $$v)},expression:"project.vendorTradeCategories"}}):_vm._e(),_c('h3',[_vm._v("Additional information (Optional)")]),_c('b-form-textarea',{attrs:{"placeholder":"Please provide additional project information, budgets, or specific requirements for this project","rows":"6"},model:{value:(_vm.project.addedDetail),callback:function ($$v) {_vm.$set(_vm.project, "addedDetail", $$v)},expression:"project.addedDetail"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',[_vm._v("Project name"),_c('sup',{staticClass:"required"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"mt-4"},[_vm._v("Type of Project"),_c('sup',{staticClass:"required"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',[_vm._v("Description"),_c('sup',{staticClass:"required"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"mt-4"},[_vm._v("Project location"),_c('sup',{staticClass:"required"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"mt-4"},[_vm._v("Estimated Project Start"),_c('sup',{staticClass:"required"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"mt-4"},[_vm._v(" Please select all contractor trade categories you would like to invite to view this project"),_c('sup',{staticClass:"required"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"mt-4"},[_vm._v(" Would you like to invite Vendors to view this project?"),_c('sup',{staticClass:"required"},[_vm._v("*")])])}]

export { render, staticRenderFns }