<template>
  <div class="px-4">
    <b-nav class="my-4 nav-list">
      <b-nav-item
        v-for="item in dynamicNavItems"
        :key="item.title"
        class="mx-2 font-weight-bold"
        active-class="border-warning border-top-0 border-left-0 border-right-0 border-bottom"
        link-classes="p-0"
        :to="item.to"
      >
        {{ item.title }}
        <span
          class="count"
          v-if="
            item.title == 'Saved Jobs' &&
            $store.state.userProfile.savedJobs.length
          "
        >
          {{ $store.state.userProfile.savedJobs.length }}
        </span>
        <span class="count" id="activity-count" v-if="item.title == 'Activity'"></span>
        <div
          v-if="
            (item.title == 'View Job Briefings' &&
              hasJobBriefingNotification) ||
            (item.title == 'Post a Project' && hasProjectNotification) ||
            (item.title == 'Post a Job' && hasJobApplicationNotification)
          "
          style="float: right; position: relative; top: -5px"
        >
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="6.97656"
              cy="6"
              r="5"
              fill="#FA0000"
              stroke="white"
              stroke-width="2"
            />
          </svg>
        </div>
      </b-nav-item>
    </b-nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "inner-layout",
  props: {
    navItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isToolbox: false,
      dynamicNavItems: [],
      hasJobBriefingNotification:
        this.$store.state.userProfile.type == "jobSeeker" &&
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) =>
            x.type == "job" && x.profile != this.$store.state.userProfile._id
        ) > -1,
      hasJobApplicationNotification:
        this.$store.state.userProfile.type == "provider" &&
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) => x.type == "job-application"
        ) > -1,
      hasProjectNotification:
        this.$store.state.userProfile.profilesRequested.findIndex(
          (x) =>
            x.type == "project" &&
            x.profile != this.$store.state.userProfile._id
        ) > -1,
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.isToolbox = route.path.includes("toolbox")
        this.isJobView = route.path.includes('employees/jobs-view')
        let isProfileOrJobView =
          (route.path.includes("toolbox") &&
          route.path.includes("update-profile")) || route.path.includes('employees/jobs-view')
        this.dynamicNavItems = (!this.isToolbox && !this.isJobView)
          ? this.navItems
          : isProfileOrJobView
          ? []
          : this.$store.state.userProfile.type === "jobSeeker"
          ? this.navItems.filter(
              (x) =>
                x.title === "Connections" || x.title === "Notification Settings"
            )
          : this.navItems
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-list {
  &:has(li) {
    border-bottom: 1px solid var(--Gray-200, #d5d9e2) !important;
  }

  li {
    margin-right: 48px !important;

    a.active {
      .count {
        background: var(--gfb-blue-brand-500, #0095E2);
      }
    }

    .count {
      display: flex;
      max-width: 24px;
      padding: 0px 18px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: var(--Gray-500, #677690);
      color: var(--White, #fff);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      position: relative;
      top: 2px;
      float: right;
      margin-left: 8px;
    }
  }
}
</style>
