<template>
  <b-modal
    hide-footer
    dialog-class="modal-no-padding custom-gfb-modal"
    :id="id"
    :title="title"
  >
    <template #modal-header="{ close }">
      <h5 class="modal-title font-26-900">{{ title }}</h5>
      <button type="button" class="close" @click="close()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </template>
    <p class="my-4 ml-4">
      {{ description }}
    </p>

    <slot name="input"></slot>

    <div class="action-btns">
      <DefaultButton
        :btnType="['cancel-btn']"
        v-if="showCancel"
        :labelText="'Cancel'"
        @click="handleCancel"
      />
      <DefaultButton
        :btnType="btnClasses"
        :labelText="'Confirm'"
        @click="handleConfirm"
      />
    </div>
  </b-modal>
</template>

<script>
import DefaultButton from "@/components/DefaultButton"
export default {
  name: "default-modal",
  components: { DefaultButton },
  props: {
    title: {
      type: String,
      default: "Modal header",
    },
    description: {
      type: String,
      default: "Modal description",
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: "common-modal",
    },
    btnClasses: {
      type: Array,
    },
  },
  methods: {
    handleConfirm() {
      this.$emit("onConfirm")
    },
    handleCancel() {
      this.supportText = ""
      this.$bvModal.hide(this.id)
      this.$emit("onCancel")
    },
  },
}
</script>

<style lang="scss" scoped>
.action-btns {
  display: flex;
  gap: 4px;
  justify-content: flex-end;
}
</style>
