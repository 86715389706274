<template>
  <form class="my-4">
    <h3>Project name<sup class="required">*</sup></h3>
    <base-input
      :class="{
        'input-group-alternative': true,
        'input-error': errors.includes('name'),
      }"
      placeholder="Project name"
      type="text"
      v-model="project.name"
    >
    </base-input>
    <span class="font-14-500 invalid" v-if="errors.includes('name')"
      >Required</span
    >

    <h3 class="mt-4">Type of Project<sup class="required">*</sup></h3>
    <base-input
      :class="{
        'input-group-alternative': true,
        'input-error': errors.includes('name'),
      }"
      placeholder="Type of Project"
      type="text"
      v-model="project.type"
    >
    </base-input>
    <span class="font-14-500 invalid" v-if="errors.includes('type')"
      >Required</span
    >

    <h3>Description<sup class="required">*</sup></h3>
    <b-form-textarea
      v-model="project.description"
      :class="{ 'input-error': errors.includes('description') }"
      placeholder="Brief description of the project"
      rows="6"
    ></b-form-textarea>
    <span class="font-14-500 invalid" v-if="errors.includes('description')"
      >Required</span
    >

    <h3 class="mt-4">Project location<sup class="required">*</sup></h3>
    <base-input
      :class="{
        'input-group-alternative': true,
        'input-error': errors.includes('zip'),
      }"
      placeholder="ZIP Code"
      type="text"
      v-model="project.zip"
      @keyup="validateZip(project.zip)"
    >
    </base-input>
    <span
      class="font-14-500 invalid"
      v-if="invalidZip || errors.includes('zip')"
    >
      Please enter a valid ZIP code
    </span>

    <h3 class="mt-4">Estimated Project Start<sup class="required">*</sup></h3>
    <base-input
      :class="{
        'input-group-alternative': true,
        'input-error': errors.includes('startDate'),
      }"
      placeholder="Select date"
      type="date"
      v-model="project.startDate"
    >
    </base-input>
    <span class="font-14-500 invalid" v-if="errors.includes('startDate')"
      >Required</span
    >

    <h3 class="mt-4">
      Please select all contractor trade categories you would like to invite to
      view this project<sup class="required">*</sup>
    </h3>
    <div
      :class="{ 'input-error': errors.includes('contractorTradeCategories') }"
    >
      <CustomMultiSelect
        :selectOptions="categoryOptions"
        :selectedList="project.contractorTradeCategories"
        selectLabel="Select Trade Categories"
        @remove-option="removeCategory"
        @select-option="addCategory"
      ></CustomMultiSelect>
      <span
        class="font-14-500 invalid"
        v-if="errors.includes('contractorTradeCategories')"
        >Required</span
      >
    </div>

    <h3 class="mt-4">
      Would you like to invite Vendors to view this project?<sup
        class="required"
        >*</sup
      >
    </h3>
    <b-form-group class="gfb-radio-container" required>
      <b-form-radio-group
        v-model="project.inviteVendorsToView"
        :options="trueFalse"
      ></b-form-radio-group>
      <span
        class="font-14-500 invalid"
        v-if="errors.includes('inviteVendorsToView')"
        >Required</span
      >
    </b-form-group>

    <multiselect
      v-model="project.vendorTradeCategories"
      placeholder="Search or add a category"
      :options="categoryOptions"
      :multiple="true"
      :taggable="true"
      :close-on-select="false"
      v-if="project.inviteVendorsToView === 'true'"
    >
    </multiselect>

    <h3>Additional information (Optional)</h3>
    <b-form-textarea
      v-model="project.addedDetail"
      placeholder="Please provide additional project information, budgets, or specific requirements for this project"
      rows="6"
    ></b-form-textarea>
  </form>
</template>

<script>
import options from "@/utils/options"
import CustomMultiSelect from "@/components/CustomMultiSelect"

export default {
  props: {
    project: Object,
    errors: Array,
  },
  components: {
    CustomMultiSelect,
  },
  data() {
    return {
      categoryOptions: options.categoryOptions,
      trueFalse: options.trueFalse,
      invalidZip: false,
    }
  },
  methods: {
    removeCategory(option) {
      this.project.contractorTradeCategories =
        this.project.contractorTradeCategories.filter(
          (category) => category !== option
        )
    },
    addCategory(option) {
      this.project.contractorTradeCategories.push(option)
    },
    validateZip(zip) {
      const invalidZip = !zip || !zip.match(/^[0-9]{5}(?:-[0-9]{4})?$/)
      this.invalidZip = invalidZip
    },
  },
}
</script>

<style scoped>
.invalid,
sup.required {
  color: #ff0000;
}
</style>
