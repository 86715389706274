<template>
    <div class="action-btn" ref="wrapper">
                <label class="btn-label" @click="togglePopover('popover-' +id, $event)">{{ btnText }} <PhCaretDown size="20" color="#FFFFFF" weight="bold" /></label>
                <div class="popover-action" ref="popover" :id="'popover-' + id">
                  <ul>
                    <li v-for="(action, i) in actions" @click="action.function(id)" :key="i">
                        {{ action.name }} <PhCheck size="20" color="#0095e2" weight="bold" />
                    </li>
                  </ul>
                </div>
    </div>
  </template>
  <script>
  import { PhCheck, PhCaretDown } from "phosphor-vue"

  export default {
    name: "action-buttons-dropdown",
    components: { PhCheck, PhCaretDown },
    props: {
      btnText: {
        type: String,
        default: "Manage",
      },
      actions: {
        type: Array,
      },
      id: {
        type: String
      }
    },
    created() {
        document.addEventListener('click', this.handleClickOutside);
    },
    methods: {
        togglePopover(id, event) {
            this.hideAllPopovers();
            let popover = document.getElementById(id)
            if (popover.style.display == 'none') {
                popover.style.display = 'block';
                event.target.classList.add("active");
            } else {
                popover.style.display = 'none';
                event.target.classList.remove("active");
            }
        },
        hideAllPopovers(){
            let popovers = document.getElementsByClassName('popover-action');
            let actionBtns = document.getElementsByClassName('action-btn');
            let labels = document.getElementsByClassName('btn-label');

            for (let i = 0; i < popovers.length; i ++) {
                popovers[i].style.display = 'none'
            }

            for (let i = 0; i < actionBtns.length; i ++) {
                actionBtns[i].classList.remove("active");
            }

            for (let i = 0; i < labels.length; i ++) {
                labels[i].classList.remove("active");
            }
        },  
        handleClickOutside(event) {
            const allPopovers = document.getElementsByClassName('popover-action');
            const allWrappers = document.getElementsByClassName('action-btn');
            let isClickInside = false;

            allWrappers.forEach((wrapper, i) => {
                if (wrapper.contains(event.target) || (allPopovers[i] && allPopovers[i].contains(event.target))) {
                isClickInside = true;
                }
            });

            if (!isClickInside) {         
                this.hideAllPopovers()
            }
        },   
    },
  }
  </script>
  <style lang="scss" scoped>
  .action-btn {
    position: relative;
    .button-wrapper {
      input[type="button"].manage-btn {
        background-color: var(--gfb-blue-brand-500, #0095e2) !important;
      }
    }

    .popover-action {
      display: none;
      min-height: 52px;
      padding: 8px;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid var(--Gray-200, #d5d9e2);
      background: var(--White, #fff);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 50px;
      z-index: 999;
      width: 100%;

      ul {
        list-style: none;
        margin-bottom: 0;

        li {
          display: flex;
          padding: 8px;
          align-items: center;
          gap: 8px;
          font-size: 16px;
          cursor: pointer;
          border-radius: 4px;
          margin-bottom: 2px;
          margin-top: 2px;

          svg {
            display: none;
          }

          &:hover,
          &:focus {
            color: var(--gfb-blue-brand-500, #0095e2);

            svg {
                display: block;
            }
          }

          &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
            pointer-events: none;
          }

          &:last-of-type {
            border-top: 1px solid var(--Gray-300, #b0b9c9);
          }
        }
      }
    }

    .btn-label {
        background-color: var(--gfb-blue-brand-500, #0095e2);
        color: #ffffff;
        display: flex;
        min-width: 106px;
        padding: 10px 10px 10px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        gap: 16px;

        svg {
            border-left: 1px solid white;
            padding-left: 5px;
        }

        &.active {
            background: var(--gfb-blue-brand-600, #0073AF) !important;
        }
    }
  } 
  </style>
  