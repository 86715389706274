<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <!-- <form class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <div class="form-group mb-0">
                <base-input placeholder="Search"
                            class="input-group-alternative"
                            alternative=""
                            addon-right-icon="fas fa-search">
                </base-input>
            </div>
        </form> -->
    <div class="ml-lg-auto"></div>
    <ul class="navbar-nav align-items-center d-none d-md-flex top-right-navbar">
      <!--li>
        <router-link to="/messages">
          <i class="fas fa-comment-dots"></i>
        </router-link>
      </li-->
      <!--li class="nav-item dropdown">
        <base-dropdown>
          <div class="media align-items-center" slot="title">
            <i
              v-if="
                ($store.state.userProfile.type == 'provider' &&
                  $store.state.userProfile.profilesRejected.length == 0 &&
                  jobApplications.length == 0) ||
                ($store.state.userProfile.type == 'jobSeeker' &&
                  $store.state.userProfile.profilesRejected.length == 0 &&
                  jobBriefings.length == 0)
              "
              class="fas fa-bell"
            ></i>
            <svg
              v-else
              width="22"
              height="23"
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.1766 25C12.4966 25 13.5766 23.92 13.5766 22.6H8.77656C8.77656 23.92 9.85656 25 11.1766 25ZM18.9766 17.8V11.2C18.9766 7.48 16.4566 4.48 12.9766 3.64V2.8C12.9766 1.84 12.1366 1 11.1766 1C10.2166 1 9.37656 1.84 9.37656 2.8V3.64C5.89656 4.48 3.37656 7.48 3.37656 11.2V17.8L1.22509 19.9515C1.06596 20.1106 0.976562 20.3264 0.976562 20.5515V20.5515C0.976562 21.0201 1.35646 21.4 1.82509 21.4H20.528C20.9967 21.4 21.3766 21.0201 21.3766 20.5515V20.5515C21.3766 20.3264 21.2872 20.1106 21.128 19.9515L18.9766 17.8Z"
                fill="#003049"
              />
              <circle
                cx="19.9766"
                cy="6"
                r="5"
                fill="#FA0000"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          </div>
          <template v-if="$store.state.userProfile.type == 'jobSeeker'">
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Notifications</h6>
            </div>
            <router-link
              v-for="n in $store.state.userProfile.profilesRejected"
              :key="n._id"
              :to="'/toolbox/notifications/' + n._id"
              class="dropdown-item"
            >
              <i class="ni ni-single-02"></i>
              <span>Job Rejected</span>
            </router-link>
            <router-link
              v-for="n in jobBriefings"
              :key="n.job"
              :to="'/jobs/' + n.job"
              class="dropdown-item"
            >
              <i class="ni ni-single-02"></i>
              <span>Job Briefing</span>
            </router-link>
          </template>
          <template v-else-if="$store.state.userProfile.type == 'provider'">
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Notifications</h6>
            </div>
            <router-link
              v-for="n in $store.state.userProfile.profilesRejected"
              :key="n._id"
              :to="'/toolbox/notifications/' + n._id"
              class="dropdown-item"
            >
              <i class="ni ni-single-02"></i>
              <span>Job Rejected</span>
            </router-link>
            <router-link
              v-for="n in jobApplications"
              :key="n.job"
              :to="'/toolbox/notifications/' + n._id"
              class="dropdown-item"
            >
              <i class="ni ni-single-02"></i>
              <span>Job Application Received</span>
            </router-link>
          </template>
        </base-dropdown>
      </li-->
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <div class="media align-items-center" slot="title">
            <span class="avatar avatar-sm rounded-circle">
              <signed-image
                v-if="prof.profilePhotoUrl"
                :file="prof.profilePhotoUrl"
                :alt="prof.name"
              />
              <div v-if="!prof.profilePhoto">
                <svg
                  width="28"
                  height="20"
                  viewBox="0 0 48 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.6969 21.6125L25.9023 20.3635C25.6797 19.1857 25.6797 17.9774 25.9023 16.7996L23.6969 15.5506C23.4432 15.4085 23.3293 15.114 23.4121 14.8399C23.9868 13.0325 24.9652 11.3977 26.244 10.0371C26.4407 9.82896 26.7617 9.77819 27.0102 9.92034L29.2157 11.1693C30.1424 10.3874 31.2088 9.78327 32.3633 9.38727V6.89451C32.3633 6.6102 32.5652 6.36143 32.85 6.30051C34.75 5.88421 36.6966 5.90451 38.5034 6.30051C38.7881 6.36143 38.99 6.6102 38.99 6.89451V9.39235C40.1393 9.79342 41.2058 10.3976 42.1377 11.1743L44.338 9.92542C44.5916 9.78327 44.9074 9.82896 45.1042 10.0422C46.3829 11.3977 47.3614 13.0325 47.9361 14.8449C48.0241 15.1191 47.905 15.4136 47.6513 15.5557L45.4459 16.8046C45.6685 17.9825 45.6685 19.1908 45.4459 20.3686L47.6513 21.6175C47.905 21.7597 48.0189 22.0541 47.9361 22.3283C47.3614 24.1357 46.3829 25.7704 45.1042 27.1311C44.9074 27.3392 44.5865 27.39 44.338 27.2478L42.1325 25.9989C41.2058 26.7807 40.1393 27.3849 38.9848 27.7809V30.2787C38.9848 30.563 38.7829 30.8118 38.4982 30.8727C36.5982 31.289 34.6516 31.2687 32.8448 30.8727C32.5601 30.8118 32.3582 30.563 32.3582 30.2787V27.7809C31.2088 27.3798 30.1424 26.7757 29.2105 25.9989L27.005 27.2478C26.7513 27.39 26.4355 27.3443 26.2388 27.1311C24.9601 25.7755 23.9816 24.1408 23.4069 22.3283C23.3293 22.0491 23.4432 21.7546 23.6969 21.6125ZM35.6767 22.6431C37.9598 22.6431 39.8184 20.8205 39.8184 18.5815C39.8184 16.3426 37.9598 14.52 35.6767 14.52C33.3936 14.52 31.535 16.3426 31.535 18.5815C31.535 20.8205 33.3936 22.6431 35.6767 22.6431Z"
                    fill="#FCBF49"
                  />
                  <path
                    d="M17.0106 33.3378L18.5706 32.4457C18.4131 31.6044 18.4131 30.7413 18.5706 29.9L17.0106 29.0079C16.8312 28.9064 16.7506 28.6961 16.8092 28.5003C17.2157 27.2093 17.9078 26.0416 18.8122 25.0697C18.9514 24.921 19.1784 24.8848 19.3542 24.9863L20.9141 25.8784C21.5696 25.3199 22.324 24.8884 23.1406 24.6055V22.825C23.1406 22.6219 23.2834 22.4442 23.4848 22.4007C24.8287 22.1034 26.2055 22.1179 27.4835 22.4007C27.6849 22.4442 27.8277 22.6219 27.8277 22.825V24.6092C28.6407 24.8957 29.395 25.3272 30.0541 25.882L31.6104 24.9899C31.7899 24.8884 32.0132 24.921 32.1524 25.0733C33.0569 26.0416 33.749 27.2093 34.1554 28.5039C34.2177 28.6997 34.1334 28.91 33.954 29.0116L32.3941 29.9037C32.5515 30.745 32.5515 31.608 32.3941 32.4494L33.954 33.3414C34.1334 33.443 34.214 33.6533 34.1554 33.8491C33.749 35.1401 33.0569 36.3078 32.1524 37.2797C32.0132 37.4283 31.7862 37.4646 31.6104 37.3631L30.0505 36.471C29.395 37.0295 28.6407 37.461 27.8241 37.7438V39.528C27.8241 39.7311 27.6813 39.9088 27.4799 39.9523C26.136 40.2497 24.7591 40.2352 23.4811 39.9523C23.2797 39.9088 23.1369 39.7311 23.1369 39.528V37.7438C22.324 37.4574 21.5696 37.0258 20.9105 36.471L19.3505 37.3631C19.1711 37.4646 18.9477 37.432 18.8086 37.2797C17.9041 36.3114 17.212 35.1437 16.8055 33.8491C16.7506 33.6497 16.8312 33.4394 17.0106 33.3378ZM25.4841 34.074C27.099 34.074 28.4136 32.7721 28.4136 31.1729C28.4136 29.5737 27.099 28.2718 25.4841 28.2718C23.8693 28.2718 22.5547 29.5737 22.5547 31.1729C22.5547 32.7721 23.8693 34.074 25.4841 34.074Z"
                    fill="#F77F00"
                  />
                  <path
                    d="M0.3911 19.3348L3.13446 17.7886C2.85755 16.3303 2.85755 14.8343 3.13446 13.376L0.3911 11.8297C0.0755501 11.6537 -0.0661259 11.2892 0.036911 10.9497C0.75173 8.71203 1.96885 6.68804 3.55949 5.00347C3.8042 4.74576 4.20347 4.6829 4.51258 4.8589L7.25594 6.40518C8.40867 5.43718 9.73527 4.68919 11.1714 4.1989V1.11263C11.1714 0.760628 11.4225 0.452629 11.7767 0.3772C14.1401 -0.138226 16.5615 -0.113084 18.809 0.3772C19.1632 0.452629 19.4143 0.760628 19.4143 1.11263V4.20519C20.844 4.70176 22.1706 5.44975 23.3297 6.41146L26.0666 4.86518C26.3822 4.68919 26.775 4.74576 27.0197 5.00976C28.6104 6.68804 29.8275 8.71203 30.5423 10.956C30.6518 11.2954 30.5037 11.66 30.1881 11.836L27.4448 13.3823C27.7217 14.8406 27.7217 16.3366 27.4448 17.7949L30.1881 19.3411C30.5037 19.5171 30.6453 19.8817 30.5423 20.2211C29.8275 22.4588 28.6104 24.4828 27.0197 26.1674C26.775 26.4251 26.3758 26.488 26.0666 26.312L23.3233 24.7657C22.1706 25.7337 20.844 26.4817 19.4079 26.972V30.0645C19.4079 30.4165 19.1567 30.7245 18.8025 30.7999C16.4391 31.3154 14.0177 31.2902 11.7703 30.7999C11.4161 30.7245 11.1649 30.4165 11.1649 30.0645V26.972C9.73527 26.4754 8.40867 25.7274 7.2495 24.7657L4.50614 26.312C4.19059 26.488 3.79776 26.4314 3.55305 26.1674C1.96242 24.4891 0.745291 22.4651 0.0304699 20.2211C-0.0661259 19.8754 0.0755501 19.5108 0.3911 19.3348ZM15.2928 20.6108C18.1328 20.6108 20.4447 18.3543 20.4447 15.5823C20.4447 12.8103 18.1328 10.5537 15.2928 10.5537C12.4529 10.5537 10.141 12.8103 10.141 15.5823C10.141 18.3543 12.4529 20.6108 15.2928 20.6108Z"
                    fill="#001A26"
                  />
                </svg>
              </div>
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold dark-text">{{
                prof.name
              }}</span>
            </div>
          </div>

          <template>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <router-link to="/toolbox/connections" class="dropdown-item">
              <i class="ni ni-settings"></i>
              <span>Toolbox</span>
            </router-link>
            <!--router-link to="/toolbox" class="dropdown-item">
              <i class="ni ni-calendar-grid-58"></i>
              <span>Activity</span>
            </router-link-->
            <div class="dropdown-item" @click="handleSupportModal()">
              <i class="ni ni-support-16"></i>
              <span>Support</span>
            </div>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item" @click="handleLogout">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </button>
          </template>
        </base-dropdown>
      </li>
    </ul>
    <support-modal v-on:send-support-request="sendSupportRequest">
    </support-modal>
  </base-nav>
</template>
<script>
import messagesMixin from "@/mixins/messagesMixin"
import SupportModal from "@/components/Modals/SupportModal"
export default {
  mixins: [messagesMixin],
  components: {
    SupportModal,
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      selected: this.$store.state.userProfile.public,
      prof: this.$store.state.userProfile,
    }
  },
  computed: {
    profile() {
      return this.$store.state.userProfile
    },
    initials() {
      const { name } = this.$store.state.userProfile
      const names = name.split(" ")
      const firstName = names[0]
      const lastName = names.length > 1 ? names.slice(-1)[0] : null
      const initials =
        firstName.charAt(0) + (lastName ? lastName.charAt(0) : "")
      return initials
    },
    jobApplications() {
      return this.$store.state.userProfile.profilesRequested.filter(
        (i) => i.type == "job-application"
      )
    },
    jobBriefings() {
      return this.$store.state.userProfile.profilesRequested.filter(
        (i) => i.type == "job"
      )
    },
  },
  mounted() {
    this.updateProfile()
  },
  watch: {
    "$store.state.userProfile": {
      handler(newValue) {
        this.prof = newValue
      },
      deep: true,
    },
  },
  methods: {
    async updateProfile() {
      const type = this.$store.state.userProfile.type
      await this.$store.dispatch(
        `api/${
          type == "jobSeeker" ? "getUserProfile" : "getCompanyUserProfile"
        }`,
        {
          userId: this.$store.state.user._id,
        }
      )
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    handleLogout() {
      try {
        this.$store.dispatch("auth/revokeToken")
        localStorage.clear()
        this.$router.push("/login")
      } catch (error) {
        this.showErrorMessage(error, "We couldn't revoke your credentials.")
      }
    },
    handleSupportModal() {
      this.$bvModal.show("support-modal")
    },
    async sendSupportRequest(data) {
      try {
        const profile = this.$store.state.userProfile
        const { id, type } = profile
        await this.$store.dispatch(
          `api/${type == "jobSeeker" ? "sendSupport" : "sendCompanySupport"}`,
          {
            data,
            profileId: id,
          }
        )
        this.$bvModal.hide("support-modal")
        this.$toast.success("Support request sent!")
      } catch (e) {
        this.$toast.error("Error sending support request")
      }
    },
  },
}
</script>

<style lang="scss">
.avatar-icon {
  font-family: var(--font-family-base);
  font-size: 3rem;
  font-weight: bold;
  width: 100%;
  height: 100%;
}
.b-dropdown {
  background: white !important;
  border-radius: 14px !important;
  border: 1px solid #273057;
  .dropdown-toggle {
    background: white !important;
    border-radius: 14px !important;
  }
}
</style>
