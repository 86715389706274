<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Search available job seekers or post an available job and attract
        multiple candidates to respond directly to your opportunities
      </span></template
    >
    <template v-slot:actions-right>
      <DefaultButton
        :labelText="'Post a job'"
        @click="$router.push('/employees/jobs/new')"
      />
    </template>

    <div>
      <div class="jobs-table">
        <div class="job-info" v-for="(job, i) in jobsWithMatches" :key="i">
          <div class="job-basics">
            <span class="job-name">{{ job.name }}</span>
            <capsule-list :items="[job.type]" :highlighted="true" />
            <span class="amount">{{ job.compensation }}</span>
            <span class="posted"> Posted on {{ job.postDate }} </span>
          </div>
          <div class="status">
            <span :class="job.deletedAt ? 'inactive' : 'active'">{{
              job.deletedAt ? "Inactive" : "Active"
            }}</span>
          </div>
          <div class="count">
            <span class="stats">{{ job.views ? job.views.length : 0 }}</span>
            <span>Views</span>
          </div>
          <div class="count">
            <span class="stats">{{
              job.applicants ? job.applicants.length : 0
            }}</span>
            <span>Applicants</span>
          </div>
          <div class="count">
            <span class="stats">{{
              job.declined ? job.declined.length : 0
            }}</span>
            <span>Declined</span>
          </div>
          <ActionButtonsDropdown
            :actions="actionsArray"
            :btnText="'Manage Post'"
            :id="job._id"
          />
        </div>
      </div>
      <div class="row pagination" v-if="jobsWithMatches.length">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="limit"
          @click="goToPage"
        ></b-pagination>
      </div>

      <DefaultModal
        description="Are you sure you want to delete this job?"
        title="Delete Job"
        @onConfirm="handleRemoveJob(deleteJobId)"
        id="delete-job-modal"
        :showCancel="true"
        :btnClasses="['delete-btn']"
      />
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import { mapState } from "vuex"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import jobs from "@/api/jobs"
import DefaultButton from "@/components/DefaultButton"
import DefaultModal from "@/components/Modals/DefaultModal"
import moment from "moment"
import ActionButtonsDropdown from "@/components/ActionButtonsDropdown"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    DefaultButton,
    DefaultModal,
    ActionButtonsDropdown,
  },
  data() {
    return {
      loading: false,
      jobsWithMatches: [],
      deleteJobId: null,
      actionsArray: [
        { name: "View Post", function: this.viewPost },
        { name: "Edit Post", function: this.editPost },
        { name: "Delete Post", function: this.handleDeleteModal },
      ],
      limit: 9,
      currentPage: 1,
      totalRows: 0,
      pageCount: 0,
    }
  },
  computed: {
    ...mapState({
      jobs: ({ jobs }) => JSON.parse(JSON.stringify(jobs.list)),
    }),
  },
  created() {
    this.getJobs()
  },
  watch: {
    currentPage: function () {
      this.getJobs()
    },
  },
  methods: {
    handleDeleteModal(id) {
      this.deleteJobId = id
      this.$bvModal.show("delete-job-modal")
    },
    viewPost(id) {
      this.$router.push(`/employees/jobs-view/${id}`)
    },
    editPost(id) {
      this.$router.push(`/employees/jobs/${id}/edit`)
    },
    goToPage(page) {
      this.currentPage = page
    },
    async getJobs() {
      this.loading = true
      await this.$store
        .dispatch("jobs/dispatchFetchJobs")
        .finally(() => (this.loading = false))

      for (let job of this.jobs) {
        this.getJobStats(job)
        job.postDate = moment(job.createdAt).format("MMM DD, YYYY")
      }

      this.jobs.forEach((job, i) => {
        this.getJobViews(job, i)
      })

      this.jobsWithMatches = this.jobs
    },
    async getJobViews(job, index) {
      let payload = {
        jobId: job._id,
        activityType: "view-job-details",
      }
      await this.$store
        .dispatch("api/getActivityLogs", payload)
        .then((response) => {
          let views = response.data
          if (views.length) {
            views = views.filter(
              (v, i, a) =>
                a.findIndex((t) => t.jobseekerId == v.jobseekerId) === i
            )
          }
          let jobs = [...this.jobsWithMatches]
          jobs[index].views = views
          this.jobsWithMatches = jobs
        })
    },
    async getJobStats(job) {
      let requests = this.$store.state.userProfile.profilesGranted
      let j = job
      j.declined = []
      j.applicants = []
      for (let r of requests) {
        if (r.type == "job-application" && r.job == j._id && r.profile) {
          if (j.applicants) {
            j.applicants.push(r)
          } else {
            j.applicants = [r]
          }
        }
      }
      requests = this.$store.state.userProfile.profilesRequested
      for (let r of requests) {
        if (r.type == "job-application" && r.job == j._id && r.profile) {
          if (j.applicants) {
            j.applicants.push(r)
          } else {
            j.applicants = [r]
          }
        }
      }

      requests = this.$store.state.userProfile.profilesRejected
      for (let r of requests) {
        if (r.type == "job-application" && r.job == j._id && r.profile) {
          if (j.applicants) {
            j.applicants.push(r)
          } else {
            j.applicants = [r]
          }

          if (j.declined) {
            j.declined.push(r)
          } else {
            j.declined = [r]
          }
        }
      }

      //remove duplicates
      if (j.applicants) {
        j.applicants = j.applicants.filter(
          (v, i, a) => a.findIndex((t) => t.profile == v.profile) === i
        )
      }

      if (j.declined) {
        j.declined = j.declined.filter(
          (v, i, a) => a.findIndex((t) => t.profile == v.profile) === i
        )
      }
      job = j
    },
    async handleRemoveJob(id) {
      try {
        await jobs.deleteJob(id)
        this.getJobs()
        this.$bvModal.hide("delete-job-modal")
        this.$toast.success("Job has been deleted successfully")
      } catch (error) {
        this.$toast.error(
          error.message || "Something went wrong while deleting the job."
        )
      }
    },
  },
}
</script>

<style scoped lang="scss">
.pagination {
  justify-content: center;
  margin-top: 50px;
}

.jobs-table {
  padding: 24px 40px;
  background: #fff;
  display: flex;
  flex-direction: column;

  .job-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--Gray-200, #d5d9e2);
    padding: 24px 0;
  }

  .status {
    width: 70px;
    text-align: left;

    span {
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 600;
      width: fit-content;

      &.active {
        background: var(--gfb-blue-brand-200, #7cd2ff);
        color: var(--gfb-blue-brand-600, #0073af);
      }

      &.inactive {
        border: 1px solid var(--Gray-500, #677690);
        color: var(--Gray-600, #525e77);
      }
    }
  }

  .job-basics {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    min-width: 200px;
    .job-name {
      color: var(--Gray-900, #343946);
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .amount {
      color: var(--Gray-800, #3a4252);
      font-size: 16px;
      font-weight: 600;
      margin: 24px 0;
    }

    .posted {
      color: var(--Gray-500, #677690);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .count {
    display: flex;
    flex-direction: column;
    color: var(--Gray-600, #525e77);
    text-align: right;
    font-size: 16px;
    font-weight: 600;

    .stats {
      color: var(--Gray-800, #3a4252);
      font-size: 18px;
    }
  }
}
</style>
