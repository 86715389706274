import InnerLayout from "@/layout/InnerLayout"

const navItems = [
  {
    title: "Connections",
    to: "/toolbox/connections",
  },
  {
    title: "Notification Settings",
    to: "/toolbox/settings",
  },
  {
    title: "Billing",
    to: "/toolbox/billing",
  },
]

export const toolboxRouter = [
  {
    path: "/toolbox",
    redirect: "/toolbox/connections",
    component: InnerLayout,
    props: { navItems },
    children: [
      {
        path: "connections",
        component: () =>
          import(
            /* webpackChunkName: "toolbox" */ "@/views/Toolbox/Connections.vue"
          ),
      },
      {
        path: "update-profile",
        component: () =>
          import(
            /* webpackChunkName: "toolbox" */ "@/views/Toolbox/UpdateProfile.vue"
          ),
      },
      {
        path: "settings",
        component: () =>
          import(
            /* webpackChunkName: "toolbox" */ "@/views/Toolbox/Settings.vue"
          ),
      },
      {
        path: "billing",
        component: () =>
          import(
            /* webpackChunkName: "toolbox" */ "@/views/Toolbox/Billing.vue"
          ),
      },
      {
        path: "notifications",
        component: () =>
          import(
            /* webpackChunkName: "toolbox" */ "@/views/Toolbox/Notifications.vue"
          ),
      },
      {
        path: "notifications/:notificationId",
        component: () =>
          import(
            /* webpackChunkName: "toolbox" */ "@/views/Toolbox/NotificationDetail.vue"
          ),
      },
    ],
  },
]
