<template>
  <div class="main-content signup-login-container">
    <div class="container">
      <div class="row justify-content-center">
        <a href="/" class="col-12 d-center-center pt-5">
          <img
            class="img-fluid brand-img"
            src="/img/brand/FullLogoColored-01.png"
            alt=""
          />
        </a>
        <div
          :class="`col-lg-${isProfileCompletion ? '12' : '6'} col-md-${
            isProfileCompletion ? '7' : '12'
          }`"
        >
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "auth-layout",
  data() {
    const storedStep = localStorage.getItem("profileStep")
    const step = storedStep ? Number(storedStep) : null
    const isProfileCompletion =
      this.$router.currentRoute.path.includes("complete-profile")
    return {
      step,
      isProfileCompletion,
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.isProfileCompletion = route.path.includes("complete-profile")
      },
      immediate: true,
    },
  },
}
</script>
<style>
.brand-img {
  width: 50%;
}
</style>
