<template>
  <div class="profile-section">
    <profile-section-header
      title="Business Information"
      @handle-edit-click="handleEditClick"
      :isEditing="isEditing"
    />
    <p v-if="profile.description" class="company-desc">
      {{ profile.description }}
    </p>

    <div class="company-profile-photos" v-if="profile.showcasePhotos">
      <VueSlickCarousel :arrows="true" v-bind="settings">
        <div v-for="photo in profile.showcasePhotos" :key="photo._id">
          <signed-image :file="photo" alt="" />
        </div>
      </VueSlickCarousel>
      <p class="images-count">
        {{ profile.showcasePhotos.length }} images total
      </p>
    </div>

    <ProfileSubSectionHeader
      title="Service Trade Categories"
      icon="trade-experience-icon"
    />
    <capsule-list
      :items="
        profile.vendor
          ? profile.vendor.tradesRelatedToProduct
          : profile.selfPerformWorkCategories
      "
      :highlighted="true"
    />

    <ProfileSubSectionHeader
      title="Operational Details"
      icon="operational-details-icon"
    />
    <OperationalDetails
      :profile="profile"
      :jobseekerCompanyView="jobseekerCompanyView"
    />

    <div v-if="profile.provider || profile.vendor">
      <ProfileSubSectionHeader
        title="Industry Organizations & Affiliations"
        icon="affiliations"
      />
      <capsule-list
        :items="profile.affiliations.map((affiliation) => affiliation.name)"
        :highlighted="true"
      />

      <ProfileSubSectionHeader
        title="Diverse Business Designations"
        icon="qualified-designation"
      />
      <capsule-list
        :items="profile.qualifiedDesignations"
        :highlighted="true"
      />

      <div v-if="profile.provider && !jobseekerCompanyView">
        <h3 class="overview-heading mt-4">Are you a general contractor?</h3>
        <capsule-list
          :items="trueFalse"
          :selectedItems="[profile.provider.isContractor]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileSectionHeader from "../ProfileSectionHeader.vue"
import ProfileSubSectionHeader from "../ProfileSubSectionHeader.vue"
import options from "@/utils/options"
import VueSlickCarousel from "vue-slick-carousel"
import "vue-slick-carousel/dist/vue-slick-carousel.css"
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css"
import OperationalDetails from "@/components/Profile/Company/OperationalDetails"

export default {
  name: "company-profile-overview",
  components: {
    ProfileSectionHeader,
    ProfileSubSectionHeader,
    VueSlickCarousel,
    OperationalDetails,
  },
  data() {
    return {
      trueFalse: options.trueFalse,
      yesNo: options.yesNo,
      workRadiusOptions: options.workRadiusOptions,
      numberOfEmployeesOptions: options.numberOfEmployeesOptions,
      licensed: this.profile.provider
        ? this.profile.provider.licensed
        : this.profile.vendor
        ? this.profile.vendor.licensedForSpecificProducts
        : null,
      settings: {
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        touchThreshold: 5,
      },
    }
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    jobseekerCompanyView: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleEditClick() {
      this.$emit("handle-edit-click")
    },
  },
}
</script>

<style lang="scss" scoped>
.company-desc {
  text-align: justify;
  color: #3a4252;
}
</style>
