<template>
  <div>
    <div class="row" v-if="matches && matches.length">
      <div v-for="c in matches" :key="c._id" class="col-lg-4">
        <employee-card :seekerId="c._id" />
      </div>
    </div>
    <div v-else>
      <h2>You have no matches for your service trade categories.</h2>
    </div>
    <div class="row pagination" v-if="matches.length">
      <b-pagination
        v-model="currentPage"
        :total-rows="total"
        :per-page="limit"
        @click="goToPage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import EmployeeCard from "@/components/Cards/EmployeeCard"

export default {
  name: "company-matches",
  components: {
    EmployeeCard,
  },
  data() {
    return {
      matches: [],
      limit: 20,
      currentPage: 1,
      total: 0,
    }
  },
  methods: {
    async getMatches() {
      const matches = await this.$store.dispatch("api/getCompanyMatches", {
        limit: this.limit,
        page: this.currentPage,
      })
      console.log(matches)
      this.matches = matches.data.results
      this.total = matches.data.total
    },
    goToPage(page) {
      console.log(page)
      this.currentPage = page
    },
  },
  mounted() {
    this.getMatches()
  },
  watch: {
    currentPage() {
      this.getMatches()
    },
  },
}
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
}
</style>
