<template>
  <div class="my-4">
    <form method="post" v-if="(briefing && !briefingSent) || !briefing">
      <div class="responsive-width py-4 mx-auto">
        <job-view
          v-if="currentView === 'review'"
          :job="fields"
          :isPreview="currentView === 'review'"
        />
        <div v-if="currentView === 'edit'">
          <b-form-group label="Job title">
            <base-input
              class="input-group-alternative"
              placeholder="Job title"
              type="text"
              v-model="fields.name"
            >
            </base-input>
            <form-error nonInput v-show="errors.name">{{
              errors.name
            }}</form-error>
          </b-form-group>

          <b-form-group label="Description">
            <b-form-textarea
              class="mb-4"
              v-model="fields.description"
              placeholder="Brief description of the position"
              rows="6"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group label="Job Trade Categories">
            <CustomMultiSelect
              :selectOptions="tradeCategoryOptions"
              :selectedList="fields.tradeCategories"
              selectLabel="Select trade categories for this job"
              @remove-option="removeTrade"
              @select-option="addTrade"
            ></CustomMultiSelect>
          </b-form-group>

          <b-form-group label="Zip Code">
            <base-input
              class="input-group-alternative"
              placeholder="Zip Code"
              v-model="fields.zip"
            >
            </base-input>
            <form-error nonInput v-show="errors.zip">{{
              errors.zip
            }}</form-error>
          </b-form-group>

          <b-form-group label="Type of Position" class="gfb-radio-container">
            <b-form-radio-group
              v-model="fields.type"
              :options="positionOptions"
            ></b-form-radio-group>
            <form-error nonInput v-show="errors.type">{{
              errors.type
            }}</form-error>
          </b-form-group>

          <b-form-group label="Qualifications">
            <CustomMultiSelect
              :selectOptions="fields.qualifications"
              :selectedList="fields.qualifications"
              selectLabel="Describe the qualifications"
              :showNoOptionsFlag="false"
              :tagFlag="true"
              @handle-tag="handleQualificationsTag"
              @remove-option="removeQualification"
              @select-option="selectQualification"
            ></CustomMultiSelect>
          </b-form-group>

          <b-form-group label="Compensation">
            <base-input
              class="input-group-alternative"
              placeholder="Enter Compensation Range"
              type="text"
              v-model="fields.compensation"
            >
            </base-input>
          </b-form-group>

          <b-form-group
            label="Select any benefits that apply (PTO, Bonus, etc.)"
          >
            <CustomMultiSelect
              :selectOptions="fields.benefits"
              :selectedList="fields.benefits"
              selectLabel="Add benefits"
              :tagFlag="true"
              :showNoOptionsFlag="false"
              @handle-tag="handleBenefitsTag"
              @remove-option="removeBenefit"
              @select-option="selectBenefit"
            ></CustomMultiSelect>
          </b-form-group>

          <b-form-group label="Why Work Here">
            <b-form-textarea
              class="mb-3"
              v-model="fields.whyWorkHere"
              placeholder="Tell potential candidates why they would like to work here."
              rows="6"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
            label="Does this job require experience?"
            class="gfb-radio-container"
          >
            <b-form-radio-group
              v-model="fields.experienceRequired"
              :options="trueFalse"
            ></b-form-radio-group>
            <form-error nonInput v-show="errors.experienceRequired">{{
              errors.experienceRequired
            }}</form-error>
          </b-form-group>
          <b-form-group
            label="Are you willing to provide paid training for this position?"
            class="gfb-radio-container"
          >
            <b-form-radio-group
              v-model="fields.willingToProvidePaidTraining"
              :options="trueFalse"
            ></b-form-radio-group>
            <form-error nonInput v-show="errors.willingToProvidePaidTraining">{{
              errors.willingToProvidePaidTraining
            }}</form-error>
          </b-form-group>
        </div>
      </div>

      <div class="d-flex justify-space-between">
        <DefaultButton
          v-if="currentView === 'review'"
          :labelText="`Back`"
          @click="$emit('set-current-view', 'edit')"
          :disabled="loading"
        />
        <DefaultButton
          v-if="currentView === 'edit'"
          :labelText="`Cancel`"
          @click="$router.push(cancelButtonTo)"
        />

        <span class="ml-auto">
          <!--span
            v-if="currentView === 'review' && !briefing"
            class="font-14-400 mr-4"
            >This posting will be active for 30 days</span
          -->
          <span
            v-if="currentView === 'review' && briefing"
            class="font-14-400 mr-4"
            >Be sure to confirm all fields before sending to the candidate</span
          >
          <DefaultButton
            :labelText="
              briefing && currentView == 'review'
                ? `Send Job Briefing`
                : currentView === 'review'
                ? 'Publish'
                : 'Next'
            "
            @click="validate"
          />
        </span>
      </div>
    </form>
    <div
      v-else-if="briefing && briefingSent && skilledWorker"
      class="w-50 py-4 mx-auto"
    >
      <h2>Success! Your job briefing was sent to {{ skilledWorker.name }}</h2>
      <p>
        If your skilled worker match accepts your job briefing, you will have
        full access to their contact information to arrange an interview and
        finalize the hiring process. If the job briefing is declined, we'll
        request feedback from them to give back to you.
      </p>
      <base-button
        class="rounded-lg"
        type="success"
        @click="goHome"
        :loading="loading"
      >
        Back to Dashboard
      </base-button>
      <DefaultButton :labelText="'Back to Dashboard'" @click="goHome" />
    </div>
    <div
      v-else-if="briefing && briefingSent && jobSeeker"
      class="w-50 py-4 mx-auto"
    >
      <h2>Success! Your job briefing was sent to {{ jobSeeker.name }}</h2>
      <p>
        If the job seeker accepts your job briefing, you will have full access
        to their contact information to arrange an interview and finalize the
        hiring process. If the job briefing is declined, we'll request feedback
        from them to give back to you.
      </p>
      <DefaultButton :labelText="'Back to Dashboard'" @click="goHome" />
    </div>
  </div>
</template>

<script>
import { nanoid } from "nanoid"
import jobs from "@/api/jobs.js"
import messagesMixin from "@/mixins/messagesMixin"
import options from "@/utils/options"
import {
  deNullifyErrors,
  normalize,
  requiredError,
  validateZipCode,
} from "@/utils/validations"
import JobView from "./JobView"
import profile from "@/api/profile"
import DefaultButton from "@/components/DefaultButton"
import CustomMultiSelect from "@/components/CustomMultiSelect"
import { ERROR_MESSAGES, API_RESPONSE_ERRORS } from "@/utils/constants"

export default {
  mixins: [messagesMixin],
  components: {
    JobView,
    DefaultButton,
    CustomMultiSelect,
  },
  props: {
    job: Object,
    loading: {
      type: Boolean,
      required: true,
    },
    currentView: {
      type: String,
      required: true,
    },
    cancelButtonTo: {
      type: String,
      required: true,
    },
    briefing: {
      type: Boolean,
      required: false,
    },
    skilledWorker: {
      type: Object,
      required: false,
    },
    jobSeeker: {
      type: Object,
      required: false,
    },
  },
  data() {
    const isPriorityAccess =
      this.$router.currentRoute.path.includes("skilled-workers")
    const isSkilledWorker =
      this.jobSeeker?.jobSeeker?.hasHandsOnExperience ||
      this.jobSeeker?.jobSeeker?.hasFormalTraining
    const job =
      this.job ??
      (localStorage.getItem("jobFields")
        ? JSON.parse(localStorage.getItem("jobFields"))
        : {})
    const {
      name = "",
      description = "",
      zip = "",
      type = "",
      qualifications = [],
      compensation = "",
      benefits = [],
      whyWorkHere = "",
      willingToProvidePaidTraining,
      experienceRequired,
    } = job
    const tradeCategories =
      job.tradeCategories ||
      this.$store.state.userProfile.selfPerformWorkCategories
    return {
      company: this.$store.state.userProfile?._id,
      user: this.$store.state.user.userId,
      jobId: null,
      errors: {},
      fields: {
        name,
        description,
        zip,
        type,
        qualifications,
        tradeCategories,
        compensation,
        benefits,
        whyWorkHere,
        willingToProvidePaidTraining,
        experienceRequired,
      },
      trueFalse: options.trueFalse,
      positionOptions: options.positionOptions,
      jobBenefitsOptions: options.jobBenefitsOptions,
      briefingSent: false,
      dataRetrieving: false,
      checkout: false,
      returnUrlPath: `/dashboard`,
      loadingChild: this.loading,
      paymentIntent: null,
      isPriorityAccess,
      isSkilledWorker,
      tradeCategoryOptions: options.categoryOptions,
    }
  },
  methods: {
    removeTrade(option) {
      this.fields.tradeCategories = this.fields.tradeCategories.filter(
        (category) => category !== option
      )
    },
    addTrade(option) {
      if (!this.fields.tradeCategories.includes(option)) {
        this.fields.tradeCategories.push(option)
      }
    },
    selectQualification(option) {
      let label = option.label ? option.label : option
      if (!this.fields.qualifications.includes(label)) {
        this.fields.qualifications.push(label)
      }
    },
    removeQualification(option) {
      this.fields.qualifications = this.fields.qualifications.filter(
        (qualification) => qualification !== option
      )
    },
    handleQualificationsTag(newTag) {
      if (!this.fields.qualifications.includes(newTag)) {
        this.fields.qualifications = this.fields.qualifications.concat(newTag)
      }
    },
    selectBenefit(option) {
      let label = option.label ? option.label : option
      if (!this.fields.benefits.includes(label)) {
        this.fields.benefits.push(label)
      }
    },
    removeBenefit(option) {
      this.fields.benefits = this.fields.benefits.filter(
        (benefit) => benefit !== option
      )
    },
    handleBenefitsTag(newTag) {
      if (!this.fields.benefits.includes(newTag)) {
        this.fields.benefits = this.fields.benefits.concat(newTag)
      }
    },
    handleTradeCategoriesTag(newTag) {
      this.fields.tradeCategories = this.fields.tradeCategories.concat(newTag)
    },
    async validate() {
      console.log("validate")
      const fields = { ...this.fields }
      const {
        name,
        zip,
        type,
        willingToProvidePaidTraining,
        experienceRequired,
      } = fields

      const errors = {
        name: requiredError(name),
        zip: requiredError(
          zip,
          !validateZipCode(zip) ? "Please enter a valid ZIP code" : null
        ),
        type: requiredError(type),
        willingToProvidePaidTraining: requiredError(
          willingToProvidePaidTraining
        ),
        experienceRequired: requiredError(experienceRequired),
      }
      if (Object.keys(deNullifyErrors(errors)).length > 0) {
        this.errors = errors
      } else {
        // Blank out the errors so they don't stay visible
        this.errors = {}
        if (this.currentView === "edit") {
          localStorage.setItem("jobFields", JSON.stringify(fields))
          if (!this.briefing) {
            //await this.createPaymentIntent()
          }
          this.$emit("set-current-view", "review")
        } else {
          fields.published = true
          this.handleSubmit(fields)
        }
      }
    },
    async createPaymentIntent() {
      const data = {
        candidate_profile_id: this.profileId,
        product_slug: this.productSlug,
        return_url: `${process.env.VUE_APP_SITE_URL}/employees/skilled-workers/profile/${this.profileId}/access/checkout-complete`,
        idempotencyKey: nanoid(),
      }
      const defaultError = new Error("We couldn't submit your payment request.")
      try {
        const response = await this.$store.dispatch(
          "api/createPaymentIntent",
          data
        )
        if (!response.data || response.data.errors) {
          throw response.data?.errors[0] ?? defaultError
        }
        const paymentIntent = response.data
        console.log("paymentIntent", paymentIntent)
        this.loading = false
        this.$router.push({ query: { payment_intent: paymentIntent.id } })
      } catch (e) {
        console.error(e)
      }
    },
    goHome() {
      this.$router.push("/dashboard")
    },
    async createJob() {
      const { data } = await jobs.postJob(normalize(this.fields), null)
      this.jobId = data.id
      console.log("DATA", data)
      this.$router.push(`${this.returnUrlPath}`)
    },
    async retrievePaymentIntent() {
      this.$emit("set-data-retrieving", true)
      const id = this.$router.currentRoute.query?.payment_intent
      if (id) {
        try {
          const { data } = await this.$store.dispatch("api/getPaymentIntent", {
            id,
          })
          if (!data) {
            throw new Error(this.defaultErrorMessage)
          }
          if (data.errors) {
            throw data.errors[0] ?? new Error(this.defaultErrorMessage)
          }
          this.paymentIntent = data
          this.$emit("set-data-retrieving", false)
        } catch (error) {
          this.logDevError(error)
          this.$emit("set-data-retrieving", false)
        }
      }
    },
    async handleSubmit(fields) {
      this.$emit("set-loading", true)
      const isEditing = !!this.job
      console.log("ISEDITING", isEditing)
      const createOrUpdate = isEditing ? jobs.updateJob : jobs.postJob
      try {
        if (!isEditing && this.briefing) {
          // CREATE JOB BRIEFING
          fields.briefing = true
          fields.jobSeeker = this.$router.currentRoute.params.profileId
          let { data } = await createOrUpdate(fields, null)
          if (data.id) {
            const payload = {
              company: this.$store.state.userProfile.name,
              jobBriefing: data.id,
              userId: this.$router.currentRoute.params.profileId,
            }
            let recipientProfileId = this.$router.currentRoute.params?.profileId
            let id = data.id
            let type = "job"
            await profile.requestProfileAccess(recipientProfileId, id, type)
            // SEND EMAIL BRIEFING
            await this.$store.dispatch("api/sendJobBriefing", payload)
            // REDIRECT TO SUCCESS PAGE
            this.briefingSent = true
            this.$emit("set-current-view", "done")
            await this.$store.dispatch("api/getUserProfile", {
              userId: this.$store.state.user._id,
            })
          }
          return
        }
        const { data } = await createOrUpdate(
          isEditing ? this.job._id : fields,
          isEditing ? fields : null
        )
        console.log("DATA", data)
        if (data.id) {
          localStorage.removeItem("jobFields")
          this.$toast.success("Congratulations, your Job has been published!")
          this.$router.push(`/employees/jobs/${data.id}`)
        }
      } catch (error) {
        if (
          error.response.status == 400 &&
          error.response.data?.error ===
            API_RESPONSE_ERRORS.INVALID_ZIP_CODE_ERROR
        ) {
          this.showErrorMessage(
            error?.errors?.[0],
            ERROR_MESSAGES.INVALID_ZIP_CODE_INPUT
          )
        } else {
          this.$toast.error(
            error.message ||
              `We couldn't ${isEditing ? "update" : "create"} this job.`
          )
        }
      } finally {
        this.$emit("set-loading", false)
      }
    },
    async handleConfirm() {
      this.$refs.productPurchase.handleConfirm()
    },
    setLoading(loading) {
      this.loadingChild = loading
    },
    setDataRetrieving(isRetrieving) {
      this.dataRetrieving = isRetrieving
    },
    goToCheckout() {
      this.checkout = !this.checkout
    },
  },
  async created() {
    this.dataRetrieving = false
  },
  watch: {},
}
</script>

<style lang="scss" scoped>
.responsive-width {
  width: 80%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
</style>
