<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Post or search for an upcoming project or task and quickly find trade
        partners to review and connect with
      </span></template
    >
    <template v-slot:actions-right>
      <DefaultButton
        :labelText="'Post a project'"
        @click="$router.push('/trade-partners/projects/new')"
      />
    </template>
    <div>
      <p v-if="loading" class="text-center">
        <b-spinner class="mx-auto d-inline-block"></b-spinner>
      </p>
      <v-client-table
        :data="projects"
        :columns="columns"
        :options="options"
        class="projects-table"
        v-else
      >
        <template slot="createdAt" slot-scope="props">
          {{ $dayjs(props.row.createdAt).format("MM-DD-YYYY") }}
        </template>
        <template slot="matches" slot-scope="props">
          <DefaultButton
            v-if="props.row.matches && props.row.matches.length"
            :labelText="`${props.row.matches.length}`"
            @click="$router.push('/trade-partners/projects/' + id + '/matches')"
          />
          <span v-else>0</span>
        </template>
        <template slot="actions" slot-scope="props" class="cursor-pointer">
          <div class="actions-col">
            <DefaultButton
              :labelText="'Edit'"
              @click="handleEdit(props.row.id)"
            />
            <DefaultButton
              :labelText="'View'"
              @click="$router.push('/trade-partners/projects/' + props.row.id)"
            />
            <DefaultButton
              :labelText="'Delete'"
              @click="handleDeleteModal(props.row._id)"
            />
          </div>
        </template>
        <template slot="contractorTradeCategories" slot-scope="props">
          <!-- {{ props.row.contractorTradeCategories }} -->
          <span
            v-for="(cat, index) in props.row.contractorTradeCategories"
            :key="cat"
            >{{ cat }}
            <span
              v-if="index + 1 !== props.row.contractorTradeCategories.length"
              >,
            </span>
          </span>
        </template>
      </v-client-table>
    </div>
    <default-modal
      description="Are you sure you want to delete this project?"
      title="Delete Project"
      @onConfirm="handleDelete(deleteId)"
      id="delete-project-modal"
      :showCancel="true"
      :btnClasses="['delete-btn']"
    />
    <toast-message ref="toastMessage" />
  </inner-layout-action-bar-wrapper>
</template>

<script>
import { mapState } from "vuex"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import projects from "@/api/projects"
import DefaultButton from "@/components/DefaultButton"

export default {
  name: "Projects",
  components: {
    InnerLayoutActionBarWrapper,
    DefaultButton,
  },
  data() {
    return {
      columns: [
        "name",
        "contractorTradeCategories",
        "createdAt",
        "matches",
        "actions",
      ],
      options: {
        headings: {
          contractorTradeCategories: "Trade Categories",
          createdAt: "Posted On",
          matches: "Matches",
          // add views
          // action: "",
        },
        columnsClasses: {
          contractorTradeCategories: "categories",
        },
        sortable: ["name", "contractorTradeCategories", "createdAt", "matches"],
      },
      loading: false,
      projects: this.$store.state.projects.list,
      deleteId: null,
    }
  },
  computed: {
    ...mapState({
      //projects: ({ projects }) => projects.list,
    }),
  },
  created() {
    this.getProjects()
  },
  methods: {
    getMatches(projects) {
      let requests = this.$store.state.userProfile.profilesRequested
      for (let r of requests) {
        for (let p of projects) {
          if (r.type == "project" && r.project == p._id) {
            if (p.matches) {
              p.matches.push(r)
            } else {
              p.matches = [r]
            }
          }
        }
      }
      requests = this.$store.state.userProfile.profilesGranted
      for (let r of requests) {
        for (let p of projects) {
          if (r.type == "project" && r.project == p._id) {
            if (p.matches) {
              p.matches.push(r)
            } else {
              p.matches = [r]
            }
          }
        }
      }
      this.projects = projects
    },
    handleDeleteModal(id) {
      this.deleteId = id
      this.$bvModal.show("delete-project-modal")
    },
    async handleDelete(id) {
      this.loading = true
      try {
        await projects.deleteProject(id)
        this.$bvModal.hide("delete-project-modal")
        this.getProjects()
        this.$refs.toastMessage.showToast(
          "Project has been deleted successfully.",
          "success"
        )
      } catch (error) {
        this.$refs.toastMessage.showToast("Something went wrong.", "error")
      } finally {
        this.loading = false
      }
    },
    async handleEdit(id) {
      //this.loading = true
      this.$router.push("/trade-partners/projects/" + id + "/edit")
    },
    async viewMatches(id) {
      //this.loading = true
      this.$router.push("/trade-partners/projects/" + id + "/matches")
    },
    async getProjects() {
      this.loading = true
      await this.$store
        .dispatch("projects/dispatchFetchProjects")
        .finally(() => (this.loading = false))
      this.projects = this.$store.state.projects.list
      this.getMatches(this.projects)
    },
  },
}
</script>

<style scoped>
.actions-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.categories {
  max-width: 100px;
  overflow: hidden;
  white-space: normal;
}
</style>
