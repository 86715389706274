<!-- your-component.vue-->
<template>
  <div
    class="form-group"
    :class="[
      classes,
      { 'has-danger': error },
      { focused: focused },
      { 'has-label': label || $slots.label },
    ]"
  >
    <label v-if="label" class="form-control-label">
      {{ label }}
      <span v-if="required">*</span>
    </label>
    <vue-phone-number-input
      :class="inputClasses"
      v-model="model"
      :required="required"
      valid-color="#cad1d7"
      :border-radius="borderRadius || 6"
      :error="!!error"
      :only-countries="['US']"
      default-country-code="US"
      no-country-selector
      no-example
    />
    <div
      class="text-danger invalid-feedback"
      style="display: block"
      v-if="error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input"

export default {
  inheritAttrs: false,
  name: "phone-input",
  components: {
    VuePhoneNumberInput,
  },
  props: {
    classes: {
      type: String,
      description: "String classes to be applied to the root element",
      default() {
        return ""
      },
    },
    inputClasses: {
      type: String,
      description:
        "String classes to be applied to the vue-phone-number-input component",
      default() {
        return ""
      },
    },
    borderRadius: {
      type: Number,
      description:
        "Override for default vue-phone-number-input `border-radius` property",
    },
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)",
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    error: {
      type: String,
      description: "Input error (below input)",
    },
    value: {
      type: String,
      description: "Input value",
    },
    isEditing: Boolean,
  },
  data() {
    return {
      focused: false,
    }
  },
  computed: {
    model: {
      get() {
        return this.$props.value
      },
      set(value) {
        this.$emit("on-change", value)
      },
    },
  },
}
</script>

<style src="vue-phone-number-input/dist/vue-phone-number-input.css"></style>

<style lang="scss" global>
.vue-phone-number-input .input-tel.input-phone-number {
  height: 43px;
  min-height: 43px;
  & input {
    background-clip: padding-box;
    height: 43px;
    min-height: 43px;
  }
}
</style>
